import React from 'react';

import useCombinedRefs from "../hoc/useCombinedRefs";
import './styles.scss';

const RadioButton = React.forwardRef((props, ref) => {
    const {type, className, id, name, checked, onChange, value, label, style} = props;
    const innerRef = React.useRef(ref);
    const combinedRef = useCombinedRefs(ref, innerRef);

    return (
        <div className={`radion-button ${className}`} style={style}>
            <input type={type} id={id} name={name} value={value} ref={combinedRef} checked={checked} onChange={onChange}/>
            <label htmlFor={id}>{label}</label>
        </div>
    );
});

export default RadioButton;