import {
    SET_INN, SET_OGRN, SET_KPP,
    SET_FIRSTNAME, SET_LASTNAME, SET_SECONDNAME,
    SET_PHONE, SET_EMAIL,
    SET_AVERAGE_NUMBER, SET_STAFF_DEBTS,
    SET_BANK_ACCOUNTS,
    SET_ADDRESSES,
    SET_STAFF_INFO,
    SET_FULL_NAME
} from '../constants/action-types'

export const setInn = (inn) => {
    return dispatch => {
        dispatch({type: SET_INN, payload: inn});
    };
};
export const setOgrn = (ogrn) => {
    return dispatch => {
        dispatch({type: SET_OGRN, payload: ogrn});
    };
};
export const setKpp = (kpp) => {
    return dispatch => {
        dispatch({type: SET_KPP, payload: kpp});
    };
};

export const setFirstName = (firstname) => {
    return dispatch => {
        dispatch({type: SET_FIRSTNAME, payload: firstname});
    };
};
export const setSecondName = (secondName) => {
    return dispatch => {
        dispatch({type: SET_SECONDNAME, payload: secondName});
    };
};
export const setLastName = (lastName) => {
    return dispatch => {
        dispatch({type: SET_LASTNAME, payload: lastName});
    };
};

export const setPhone = (phone) => {
    return dispatch => {
        dispatch({type: SET_PHONE, payload: phone});
    };
};
export const setEmail = (email) => {
    return dispatch => {
        dispatch({type: SET_EMAIL, payload: email});
    };
};

export const setAverageNumber = (number) => {
    return dispatch => {
        dispatch({type: SET_AVERAGE_NUMBER, payload: number});
    };
};
export const setStaffDebts = (debts) => {
    return dispatch => {
        dispatch({type: SET_STAFF_DEBTS, payload: debts});
    };
};

export const setBankAccounts = (account) => {
    return dispatch => {
        dispatch({type: SET_BANK_ACCOUNTS, payload: account});
    };
};

export const setAddresses = (address) => {
    return dispatch => {
        dispatch({type: SET_ADDRESSES, payload: address});
    };
};

export const setStaffInfo = (averageNumber, staffDebts) => {
    return dispatch => {
        dispatch({type: SET_STAFF_INFO, averageNumber, staffDebts});
    };
};

export const setFullName = (name) => {
    return dispatch => {
        dispatch({type: SET_FULL_NAME, payload: name});
    };
};