import React from "react";

import "./styles.scss";

function Button({style, text, type, onClick}) {

    return (
        <button className={`button`} type={type} style={style} onClick={onClick}>
            {text}
        </button>
    )
}

export default Button;