import React from 'react';

import './styles.scss';

const Input = React.forwardRef((props, ref) => {
    const {label, type, id, placeholder, className, value, onChange, name, errors, style, readOnly} = props;

    return (
        <div style={style} className={`input-group ${className}`}>
            <label htmlFor={id}>{label}</label>
            <input
                type={type}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                name={name}
                // ref={combinedRef}
                readOnly={readOnly}
            />
            <p className="error-message">{errors}</p>
        </div>
    );

});

export default Input;