import React, {useEffect} from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';

import * as Actions from '../../actions';
import Input from "../../components/input/Input";
import Checkbox from "../../components/checkbox/Checkbox";
import RadioButton from "../../components/radio-button/RadioButton";
import AddButton from "../../components/button/AddButton";
import SwitchSlider from "../../components/switch-slider/SwitchSlider";
import Button from "../../components/button/Button";
import FileUpload from "../../components/file-upload/FileUpload";
import InputWithDadata from "../../components/input/InputWithDadata";
import history from "../../history";
import "./styles.scss"

function Profile(props) {
    const {profile} = props;

    useEffect(() => {
        props.setStaffInfo(profile.averageNumber, profile.staffDebts)
    }, [profile.averageNumber, profile.staffDebts]);

    //bank accounts dynamic form adding
    const handleAddAccountsFields = () => {
        const values = [...profile.bankAccounts];
        values.push({name: '', bik: '', number: ''});
        props.setBankAccounts(values)
    };

    //bank accounts inputs change
    const handleInputChange = (index, tar, event) => {
        const values = [...profile.bankAccounts];

        if (tar === '') {
            const name = event.target.name;
            values[index][name] = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;
        } else {
            values[index][tar] = event;
        }

        props.setBankAccounts(values)
    };

    //addresses dynamic form adding
    const handleAddAddressesFields = () => {
        const values = [...profile.addresses];
        values.push({addressType: [], address: '', flat: '', addressOwnType: ''});
        props.setAddresses(values)
    };

    //addresses inputs change
    const handleAddressesInputChange = (index, tar, event) => {
        const values = [...profile.addresses];

        if (tar === '') {
            const name = event.target.name;
            values[index][name] = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;
        } else {
            values[index][tar] = event;
        }

        // values[index].addressOwnType = profile.addressOwnType;

        props.setAddresses(values)
    };

    const onContinue = (e) => {
        e.preventDefault();
        console.log(profile);
        history.push('/dashboard/physical-person')
    };

    const addAddressTypes = (e, index) => {
        const values = [...profile.addresses];
        let arr = [...profile.addresses[index].addressType];

        values[index].addressType = !e.target.checked ?
            arr.filter(item => item !== e.target.name) :
            [...arr, e.target.name];

        props.setAddresses(values)
    };

    const addAddressOwnType = (e, index) => {
        const values = [...profile.addresses];
        values[index].addressOwnType = e.target.value;
        //addressOwnType
        props.setAddresses(values)
    };

    // console.log(profile.addresses);

    return (
        <div className="profile">
            <h1>Анкета юридического лица</h1>
            <form className={'form'}>
                <div className="info">
                    <InputWithDadata
                        className="info-inputs"
                        id="inn"
                        label="ИНН"
                        placeholder="–"
                        name="inn"
                        style={{width: '333px'}}
                        service="party"
                        onChange={(e) => props.setInn(e)}
                        onSelect={s => {
                            props.setInn(s.data.inn);
                            props.setOgrn(s.data.ogrn);
                            props.setKpp(s.data.kpp);
                            props.setFullName(s.data.name.full_with_opf)
                        }}
                        query={profile.inn}
                    />
                    <Input
                        className="info-inputs"
                        id="ogrn"
                        label="ОГРН"
                        type="text"
                        placeholder="–"
                        name="ogrn"
                        style={{width: '333px'}}
                        onChange={(e) => props.setOgrn(e.target.value)}
                        value={profile.ogrn}
                    />
                    <Input
                        className="info-inputs"
                        id="kpp"
                        label="КПП"
                        type="text"
                        placeholder="–"
                        name="kpp"
                        style={{width: '333px'}}
                        onChange={(e) => props.setKpp(e.target.value)}
                        value={profile.kpp}
                    />
                </div>
                <div className="contacts">
                    <h3>Контакты</h3>
                    <div style={{display: 'flex', marginBottom: '12px', flexWrap: 'wrap'}}>
                        <Input
                            className="contacts-inputs"
                            id="lastName"
                            label="Фамилия"
                            type="text"
                            placeholder="—"
                            name="lastName"
                            style={{width: '333px'}}
                            onChange={(e) => props.setLastName(e.target.value)}
                            value={profile.lastName}
                        />
                        <Input
                            className="contacts-inputs"
                            id="firstName"
                            label="Имя"
                            type="text"
                            placeholder="—"
                            name="firstName"
                            style={{width: '333px'}}
                            onChange={(e) => props.setFirstName(e.target.value)}
                            value={profile.firstName}
                        />
                        <Input
                            className="contacts-inputs"
                            id="secondName"
                            label="Отчество"
                            type="text"
                            placeholder="—"
                            name="secondName"
                            style={{width: '333px'}}
                            onChange={(e) => props.setSecondName(e.target.value)}
                            value={profile.secondName}
                        />
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Input
                            className="contacts-inputs"
                            id="phone"
                            label="Телефон"
                            type="number"
                            placeholder="—"
                            name="phone"
                            style={{width: '333px'}}
                            onChange={(e) => props.setPhone(e.target.value)}
                            value={profile.phone}
                        />
                        <InputWithDadata
                            className="contacts-inputs"
                            id="email"
                            label="Электронная почта"
                            placeholder="—"
                            name="email"
                            style={{width: '333px'}}
                            service="email"
                            onChange={(e) => props.setEmail(e)}
                            onSelect={s => {
                                props.setEmail(s.value);
                            }}
                            query={profile.email}
                        />
                    </div>
                </div>
                <div className="companies-addresses">
                    <h3>Адреса компании</h3>
                    {
                        profile.addresses.map((address, index) => {
                            return <div className="address-info-block" key={index}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: '20px',
                                    flexWrap: 'wrap'
                                }}>
                                    <div className="checkboxes-block">
                                        <Checkbox
                                            label="Факт"
                                            className=""
                                            type="checkbox"
                                            name="legal"
                                            onChange={e => {
                                                handleAddressesInputChange(index, '', e);
                                                addAddressTypes(e, index)
                                            }}
                                        />
                                        <Checkbox
                                            label="Юридич"
                                            className=""
                                            type="checkbox"
                                            name="actual"
                                            onChange={e => {
                                                handleAddressesInputChange(index, '', e);
                                                addAddressTypes(e, index)
                                            }}
                                        />
                                        <Checkbox
                                            label="Почт"
                                            className=""
                                            type="checkbox"
                                            name="postal"
                                            onChange={e => {
                                                handleAddressesInputChange(index, '', e);
                                                addAddressTypes(e, index)
                                            }}
                                        />
                                    </div>
                                    <div className="radio-block">
                                        <RadioButton
                                            label="Аренда"
                                            className=""
                                            type="radio"
                                            name="addressOwnType"
                                            id={`rent${index}`}
                                            onChange={e => {
                                                handleAddressesInputChange(index, '', e);
                                                addAddressOwnType(e, index)
                                            }}
                                            value='rent'
                                        />
                                        <RadioButton
                                            label="Собственность"
                                            className=""
                                            type="radio"
                                            name="addressOwnType"
                                            id={`property${index}`}
                                            onChange={e => {
                                                handleAddressesInputChange(index, '', e);
                                                addAddressOwnType(e, index)
                                            }}
                                            value='property'
                                        />
                                    </div>
                                </div>
                                <div className='inputs-block'>
                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <InputWithDadata
                                            className=""
                                            id={`address${index}`}
                                            label="Адрес"
                                            placeholder="—"
                                            name="address"
                                            style={{width: '510px', marginRight: '20px', marginBottom: '8px'}}
                                            onChange={e => handleAddressesInputChange(index, 'address', e)}
                                            onSelect={s => handleAddressesInputChange(index, 'address', s.value)}
                                            query={profile.addresses[index].address}
                                        />
                                        <Input
                                            className=""
                                            id={`flat${index}`}
                                            label="Комментарий"
                                            type="text"
                                            placeholder="—"
                                            name="flat"
                                            style={{width: '510px'}}
                                            onChange={event => handleAddressesInputChange(index, '', event)}
                                            value={profile.addresses[index].flat}
                                        />
                                    </div>
                                    <div className=" flex-centered">
                                        <FileUpload
                                            className=''
                                            id={`rentScan${index}`}
                                            label="Загрузить скан договора"
                                            accept="image/*"
                                            name=""
                                        />
                                    </div>
                                </div>
                            </div>
                        })
                    }

                    <div className=" flex-centered">
                        <AddButton onClick={() => handleAddAddressesFields()}/>
                    </div>
                </div>
                <div className="company-accounts">
                    <h3>Счета в компании</h3>
                    {
                        profile.bankAccounts.map((item, index) => {
                            return <div className="inputs" key={index}>
                                <InputWithDadata
                                    className=""
                                    id="name"
                                    label="Наименование банка"
                                    placeholder="—"
                                    name="name"
                                    style={{width: '245px', marginRight: '20px'}}
                                    service="bank"
                                    onChange={e => handleInputChange(index, 'name', e)}
                                    onSelect={s => {
                                        handleInputChange(index, 'name', s.value);
                                        handleInputChange(index, 'bik', s.data.bic)
                                    }}
                                    query={profile.bankAccounts[index].name}
                                />
                                <Input
                                    className=""
                                    id={`bik${index}`}
                                    label="БИК"
                                    type="text"
                                    placeholder="—"
                                    name="bik"
                                    style={{width: '245px', marginRight: '20px'}}
                                    onChange={event => handleInputChange(index, '', event)}
                                    value={profile.bankAccounts[index].bik}
                                />
                                <Input
                                    className=""
                                    id={`number${index}`}
                                    label="Номер счёта"
                                    type="number"
                                    placeholder="—"
                                    name="number"
                                    style={{width: '245px', marginRight: '20px'}}
                                    onChange={event => handleInputChange(index, '', event)}
                                    value={profile.bankAccounts.number}
                                />
                                <SwitchSlider
                                    name="useInDocuments"
                                    label="Основной"
                                    onChange={event => handleInputChange(index, '', event)}
                                    value={profile.bankAccounts[index].useInDocuments}
                                />
                            </div>
                        })
                    }
                    <div className="flex-centered">
                        <AddButton onClick={() => handleAddAccountsFields()}/>
                    </div>
                </div>
                <div className='person-detail'>
                    <h3>Сведения о персонале</h3>
                    <div className='inputs'>
                        <Input
                            className=""
                            id="averageNumber"
                            label="Среднесписочная численность"
                            type="number"
                            placeholder="—"
                            name="averageNumber"
                            style={{width: '245px', marginRight: '20px'}}
                            onChange={e => props.setAverageNumber(e.target.value)}
                            value={profile.averageNumber}
                        />
                        <Input
                            className=""
                            id="staffDebts"
                            label="Задолженность по з/п"
                            type="number"
                            placeholder="—"
                            name="staffDebts"
                            style={{width: '245px', marginRight: '20px'}}
                            onChange={e => props.setStaffDebts(e.target.value)}
                            value={profile.staffDebts}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '48px', paddingRight: '20px'}}>
                    <Button
                        onClick={onContinue}
                        text="Продолжить"
                        style={{width: '100%'}}
                    />
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        profile: store.profile
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setInn: Actions.setInn,
        setOgrn: Actions.setOgrn,
        setKpp: Actions.setKpp,
        setFirstName: Actions.setFirstName,
        setLastName: Actions.setLastName,
        setSecondName: Actions.setSecondName,
        setPhone: Actions.setPhone,
        setEmail: Actions.setEmail,
        setAverageNumber: Actions.setAverageNumber,
        setStaffDebts: Actions.setStaffDebts,
        setBankAccounts: Actions.setBankAccounts,
        setAddresses: Actions.setAddresses,
        setStaffInfo: Actions.setStaffInfo,
        setFullName: Actions.setFullName,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile)