import {
    SET_INN, SET_OGRN, SET_KPP,
    SET_FIRSTNAME, SET_LASTNAME, SET_SECONDNAME,
    SET_PHONE, SET_EMAIL,
    SET_AVERAGE_NUMBER, SET_STAFF_DEBTS,
    SET_BANK_ACCOUNTS,
    SET_ADDRESSES,
    SET_STAFF_INFO,
    SET_FULL_NAME
} from '../constants/action-types';

const initialState = {
    inn: '', ogrn: '', kpp: '',
    firstName: '', lastName: '', secondName: '',
    phone: '', email: '',
    averageNumber: '', staffDebts: '',
    addressOwnType: 'rent', addressType: [],
    bankAccounts: [{name: '', bik: '', number: '', useInDocuments: false}],
    addresses: [{addressType: [], address:'', flat: '', addressOwnType: ''}],
    staffInfo: {averageNumber: '', staffDebts: ''},
    fullName: ''
};

function profileReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INN:
            return {...state, inn: action.payload};
        case SET_OGRN:
            return {...state, ogrn: action.payload};
        case SET_KPP:
            return {...state, kpp: action.payload};
        case SET_FIRSTNAME:
            return {...state, firstName: action.payload};
        case SET_LASTNAME:
            return {...state, lastName: action.payload};
        case SET_SECONDNAME:
            return {...state, secondName: action.payload};
        case SET_PHONE:
            return {...state, phone: action.payload};
        case SET_EMAIL:
            return {...state, email: action.payload};
        case SET_STAFF_DEBTS:
            return {...state, staffDebts: action.payload};
        case SET_AVERAGE_NUMBER:
            return {...state, averageNumber: action.payload};
        case SET_BANK_ACCOUNTS:
            return {...state, bankAccounts: action.payload};
        case SET_ADDRESSES:
            return {...state, addresses: action.payload};
        case SET_STAFF_INFO:
            return {...state, staffInfo: {averageNumber: action.averageNumber, staffDebts: action.staffDebts}};
        case SET_FULL_NAME:
            return {...state, fullName: action.payload};
        default:
            return state;
    }
}

export default profileReducer