import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import PageNotFound from './pages/page-not-found/PageNotFound';
import Dashboard from './pages/dashboard/Dashboard';
import SuccessPage from "./pages/success/Success";

const AppRoutes = () => (
    <Switch>
        <Route exact path="/" component={Dashboard}/>
        <Route path="/dashboard" component={Dashboard}/>
        <Route path="/success" component={SuccessPage}/>
        <Route path='/page-not-found' component={PageNotFound} />
        <Redirect from='*' to='/page-not-found' />
    </Switch>
);

export default AppRoutes