import React from 'react';
import DadataSuggestions from "react-dadata-suggestions";

import './styles.scss';
import "react-dadata-suggestions/dist/styles.css";

const InputWithDadata = React.forwardRef((props, ref) => {
    const {label, id, placeholder, className, service, onChange, onSelect, name, errors, value, style, query} = props;

    return (
        <div style={style} className={`input-group ${className}`}>
            <label htmlFor={id}>{label}</label>
            <DadataSuggestions
                token="2e0ba81ee423796e5090d5d247f385d0213e34cd"
                onSelect={onSelect}
                deferRequestBy={600}
                placeholder={placeholder}
                onChange={onChange}
                service={service}

                name={name}
                value={value}
                id={id}
                query={query}
            />
            <p className="error-message">{errors}</p>
        </div>
    );

});

export default InputWithDadata;