export const asideMenuItems = [
    {
        id: 1,
        name: 'Анкета',
        to: 'profile'
    },
    {
        id: 2,
        name: 'Физ лица',
        to: 'physical-person'
    },
    {
        id: 3,
        name: 'Документы',
        to: 'documents'
    },
];
