import React from 'react';
import {NavLink} from 'react-router-dom'

import * as constants from "../../constants"
import './styles.scss';

const AsideMenu = () => (
    <aside className="aside-menu">
       <div className="aside-menu-inner">
           <img src={constants.images.logo} alt="logo"/>
           <div className="aside-menu-items">
               {
                   constants.asideMenuItems.map(item => {
                       return <NavLink to={`/dashboard/${item.to}`}
                                       key={item.id}
                                       activeClassName="active-route">
                           <div key={item.id} className="align-centered">
                               <span className='flex-centered' style={{backgroundImage: `url(${constants.images['menuIcon' + item.id]})`}}> </span>
                               <span className='flex-centered-active' style={{backgroundImage: `url(${constants.images['menuIconActive' + item.id]})`}}> </span>
                               <span>{item.name}</span>
                           </div>
                       </NavLink>
                   })
               }
           </div>
       </div>
    </aside>
);

export default AsideMenu;

