import React from "react";

import * as constants from '../../constants'
import "./styles.scss";

function AddButton({onClick, style}) {

    return (
        <div className={`add-button`} style={style} onClick={onClick}>
            <img src={constants.images.plusIcon} alt="plusIcon"/>
        </div>
    )
}

export default AddButton;