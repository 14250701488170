import React from 'react';

import AppRoutes from "./routes";
import './App.scss';

function App() {
    return (
        <React.Fragment>
            <AppRoutes/>
        </React.Fragment>
    );
}

export default App;
