import React from "react"

import Button from "../../components/button/Button";
import * as constants from '../../constants';
import history from "../../history";
import './styles.scss'

const SuccessPage = () => (
    <section className="success-page flex-centered">
        <img src={constants.images.logoSucces} alt='logo'/>
        <div className='success-text'>
            <span>Спасибо!</span>
            <span>Ваша заявка успешно отправлена</span>
        </div>
        <Button
            onClick={() => history.push('/')}
            text="Создать еще одну"
            style={{width: '54%'}}
        />
    </section>
);

export default SuccessPage