import React, {useEffect} from 'react';
import {Switch, Route, useRouteMatch} from "react-router-dom";

import AsideMenu from "../../components/aside-menu/AsideMenu";
import Profile from "../profile/Profile";
import PhisicalPerson from "../phisical-person/PhisicalPerson";
import Documents from "../documents/Documents";
import history from "../../history";

import './styles.scss';

const DashBoard = ({...props}) => {
    let {path} = useRouteMatch();

    useEffect(() => {
        if (props.location.pathname === '/' || props.location.pathname === '/dashboard' || props.location.pathname === '/dashboard/') {
            history.push('/dashboard/profile')
        }
    });

    return (
        <main className="dashboard-page">
            <AsideMenu/>

            <section className="content">
                <Switch>
                    <Route path={`${path}/profile`} component={Profile}/>
                    <Route path={`${path}/physical-person`} component={PhisicalPerson}/>
                    <Route path={`${path}/documents`} component={Documents}/>
                    <Route path={`${path}/`} component={Profile}/>
                </Switch>
            </section>
        </main>
    );
};

export default DashBoard;
