import React from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';

import * as constants from "../../constants"
import * as Actions from '../../actions';
import Button from "../../components/button/Button";
import FileUpload from "../../components/file-upload/FileUpload";
import "./styles.scss"

function Documents(props) {
    const {profile, person} = props;
    const {WEBHOOK_API} = constants;

    const onApplicationSend = (e) => {
        e.preventDefault();
        for(let i in person.persons) {
            // console.log(person.persons[i])
            delete person.persons[i].beneficary;
            delete person.persons[i].CompanyHead;
            delete person.persons[i].founder;
            delete person.persons[i].signer;
        }

        // const persons = [
        //     {
        //         inn: person.inn,
        //         lastName: person.lastName,
        //         firstName: person.firstName,
        //         secondName: person.secondName,
        //         type: person.type,
        //         sharePercent: person.sharePercent,
        //         benSharePercent: person.benSharePercent,
        //         position: person.positionSigner,
        //         email: person.email,
        //         workPhone: person.phone,
        //         byProxy: person.byProxy,
        //         proxyName: person.proxyName,
        //         proxyNumber: person.proxyNumber,
        //         proxyDateEnd: person.proxyDateEnd,
        //         proxyDateStart: person.proxyDateStart
        //     }
        // ];
        //
        const principal = {
            principal: {
                addresses: profile.addresses,
                bankAccounts: profile.bankAccounts,
                contactPerson: {
                    lastName: profile.lastName,
                    firstName: profile.firstName,
                    secondName: profile.secondName,
                    email: profile.email,
                    workPhone: profile.phone
                },
                persons: person.persons,
                fullName: profile.fullName,
                inn: profile.inn,
                kpp: profile.kpp,
                ogrn: profile.ogrn,
                staffInfo: profile.staffInfo
            }
        };
        //
        console.log(principal)

        fetch(`${WEBHOOK_API}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(principal)
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
            })
            .catch(e => console.log(e))

    };

    return (
        <div className="accounting">
            <h1>Документы компании</h1>
            <form>
                <div className="info">
                    <div className="flex-centered ">
                        <FileUpload
                            className=''
                            id='yearReporting'
                            label="Бухгалтерская отчетность за последний завершенный год"
                            name=""
                        />
                    </div>
                    <div className="flex-centered ">
                        <FileUpload
                            className=''
                            id='quarterReporting'
                            label="Бухгалтерская отчетность за последний завершенный квартал"
                            name=""
                        />
                    </div>
                    <div className="flex-centered ">
                        <FileUpload
                            className=''
                            id='2quarterReporting'
                            label="Налоговая отчетность за последние 2 завершенных квартала, либо за 1 завершенный год"
                            name=""
                        />
                    </div>
                    <div className="flex-centered ">
                        <FileUpload
                            className=''
                            id='phisicalPersonu'
                            label="Устав юридического лица"
                            name=""
                        />
                    </div>

                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px', paddingRight: '20px'}}>
                    <Button
                        onClick={onApplicationSend}
                        text="Отправить заявку"
                        style={{width: '100%'}}
                    />
                </div>
            </form>
        </div>
    )
}


const mapStateToProps = (store) => {
    return {
        profile: store.profile,
        person: store.person
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setInn: Actions.setInn,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Documents)