import React from 'react';

import useCombinedRefs from "../hoc/useCombinedRefs";
import './styles.scss';

const Checkbox = React.forwardRef((props, ref) => {
    const {type, className, name, checked, onChange, value, label, style} = props;
    const innerRef = React.useRef(ref);
    const combinedRef = useCombinedRefs(ref, innerRef);

    return (
        <div className={`checkbox ${className}`} style={style}>
            <label>
                <input type={type} name={name} value={value} ref={combinedRef} onChange={onChange} checked={checked}/>
                <span>{label}</span>
            </label>
        </div>
    );
});

export default Checkbox;