import React from 'react';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';

import * as Actions from '../../actions';
import Input from "../../components/input/Input";
import AddButton from "../../components/button/AddButton";
import SwitchSlider from "../../components/switch-slider/SwitchSlider";
import Button from "../../components/button/Button";
import InputWithDadata from "../../components/input/InputWithDadata";
import FileUpload from "../../components/file-upload/FileUpload";
import history from "../../history";
import "./styles.scss"

function PhisicalPerson(props) {
    const {person} = props;

    //persons dynamic form adding
    const handleAddPersonsForm = () => {
        const values = [...person.persons];

        values.push({
            inn: "",
            lastName: "", firstName: "", secondName: "",
            type: [],
            sharePercent: "", benSharePercent: "",
            position: "",
            email: "", phone: "",
            byProxy: false,
            proxyName: "", proxyNumber: "", proxyDateEnd: "", proxyDateStart: "",
            positionHead: "",
            beneficary: false, CompanyHead: false, founder: false, signer: false,
        });
        props.setPersonsInfo(values)
    };

    //persons inputs change
    const handlePersonsInputChange = (index, tar, event) => {
        const values = [...person.persons];

        if (tar === '') {
            const name = event.target.name;
            values[index][name] = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;
        } else {
            values[index][tar] = event;
        }

        props.setPersonsInfo(values)
    };

    const onContinue = (e) => {
        e.preventDefault();
        console.log(person);
        history.push('/dashboard/documents')
    };

    const addPersonTypes = (e, index) => {
        const values = [...person.persons];
        let arr = [...person.persons[index].type];

        values[index].type = !e.target.checked ?
            arr.filter(item => item !== e.target.name) :
            [...arr, e.target.name];
        props.setPersonsInfo(values)
    };

    console.log(person.persons);
    return (
        <div className="phisical-person">
            <h1>Сведения о физических лицах</h1>
            <form className={'form'}>
                {
                    person.persons.map((item, index) => {
                        return <div style={{marginTop: '20px'}} key={index}>
                            <div className="info">
                                <div style={{display: "flex", marginBottom: '12px', flexWrap: 'wrap'}}>
                                    <Input
                                        className="info-inputs"
                                        id={`lastName${index}`}
                                        label="Фамилия"
                                        type="text"
                                        placeholder="—"
                                        name="lastName"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].lastName}
                                    />
                                    <Input
                                        className="info-inputs"
                                        id={`firstName${index}`}
                                        label="Имя"
                                        type="text"
                                        placeholder="—"
                                        name="firstName"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].firstName}
                                    />
                                    <Input
                                        className="info-inputs"
                                        id={`secondName${index}`}
                                        label="Отчество"
                                        type="text"
                                        placeholder="—"
                                        name="secondName"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].secondName}
                                    />
                                </div>
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <Input
                                        className="info-inputs"
                                        id={`phone${index}`}
                                        label="Телефон"
                                        type="number"
                                        placeholder="—"
                                        name="phone"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].phone}
                                    />
                                    <InputWithDadata
                                        className="info-inputs"
                                        id={`email${index}`}
                                        label="Электронная почта"
                                        placeholder="—"
                                        name="email"
                                        style={{width: '333px'}}
                                        service="email"
                                        onChange={e => handlePersonsInputChange(index, 'email', e)}
                                        onSelect={s => handlePersonsInputChange(index, 'email', s.value)}
                                        query={person.persons[index].email}
                                    />
                                    <InputWithDadata
                                        className="info-inputs"
                                        id={`inn${index}`}
                                        label="ИНН"
                                        placeholder="–"
                                        name="inn"
                                        style={{width: '333px'}}
                                        service="party"
                                        onChange={e => handlePersonsInputChange(index, 'inn', e)}
                                        onSelect={s => handlePersonsInputChange(index, 'inn', s.data.inn)}
                                        query={person.persons[index].inn}
                                    />
                                </div>
                            </div>
                            <div className=" flex-centered">
                                <FileUpload
                                    className=''
                                    id={`passportScan${index}`}
                                    label="Загрузить скан паспорта"
                                    accept="image/*"
                                    name=""
                                />
                            </div>

                            <div className='person-roles'>
                                <h3>Роли физлица в организации</h3>
                                <SwitchSlider
                                    name="founder"
                                    label="Акционер / Учредитель (1 и более %)"
                                    style={{marginBottom: '20px'}}
                                    onChange={e => {
                                        addPersonTypes(e, index);
                                        handlePersonsInputChange(index, '', e)
                                    }}
                                    value={person.persons[index].founder}
                                />
                                <div style={{
                                    display: person.persons[index].founder ? 'flex' : 'none',
                                    flexWrap: "wrap"
                                }}>
                                    <Input
                                        className="info-inputs"
                                        id={`sharePercent${index}`}
                                        label="Доля в уставном капитале"
                                        type="text"
                                        placeholder="—"
                                        name="sharePercent"
                                        style={{width: '333px', marginBottom: '20px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].sharePercent}
                                    />
                                </div>
                                <SwitchSlider
                                    name="CompanyHead"
                                    label="Руководитель"
                                    style={{marginBottom: '20px', marginTop: '13px'}}
                                    onChange={e => {
                                        addPersonTypes(e, index);
                                        handlePersonsInputChange(index, '', e)
                                    }}
                                    value={person.persons[index].CompanyHead}
                                />
                                <div style={{
                                    display: person.persons[index].CompanyHead ? 'flex' : "none",
                                    marginBottom: '20px',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{display: 'flex', flexWrap: "wrap"}}>
                                        <Input
                                            className="info-inputs"
                                            id={`position${index}`}
                                            label="Должность"
                                            type="text"
                                            placeholder="—"
                                            name="positionHead"
                                            style={{width: '245px'}}
                                            onChange={e => handlePersonsInputChange(index, '', e)}
                                            value={person.persons[index].positionHead}
                                        />
                                        <Input
                                            className="info-inputs"
                                            id={`authorizingDoc${index}`}
                                            label="Основание полномочий"
                                            type="text"
                                            placeholder="—"
                                            name="authorizingDoc"
                                            style={{width: '245px'}}
                                            onChange={e => handlePersonsInputChange(index, '', e)}
                                            value={person.persons[index].authorizingDoc}
                                        />
                                        <Input
                                            className="info-inputs"
                                            id={`eioProtocolDate${index}`}
                                            label="Дата протокола ЕИО"
                                            type="text"
                                            placeholder="—"
                                            name="eioProtocolDate"
                                            style={{width: '245px'}}
                                            onChange={e => handlePersonsInputChange(index, '', e)}
                                            value={person.persons[index].eioProtocolDate}
                                        />
                                        <Input
                                            className="info-inputs"
                                            id={`eioProtocolNumber${index}`}
                                            label="Номер протокола ЕИО"
                                            type="text"
                                            placeholder="—"
                                            name="eioProtocolNumber"
                                            style={{width: '245px'}}
                                            onChange={e => handlePersonsInputChange(index, '', e)}
                                            value={person.persons[index].eioProtocolNumber}
                                        />
                                    </div>
                                    <div className=" flex-centered">
                                        <FileUpload
                                            className=''
                                            id={`decisionScan${index}`}
                                            label="Загрузить скан Решения о назначении"
                                            accept="image/*"
                                            name=""
                                        />
                                    </div>
                                </div>
                                <SwitchSlider
                                    name="beneficary"
                                    label="Бенифициар"
                                    style={{marginBottom: '20px'}}
                                    onChange={e => {
                                        addPersonTypes(e, index);
                                        handlePersonsInputChange(index, '', e)
                                    }}
                                    value={person.persons[index].beneficary}
                                />
                                <div style={{
                                    display: person.persons[index].beneficary ? 'flex' : 'none',
                                    marginBottom: '20px',
                                    flexWrap: "wrap"
                                }}>
                                    <Input
                                        className="info-inputs"
                                        id={`benSharePercent${index}`}
                                        label="Доля в уставном капитале"
                                        type="text"
                                        placeholder="—"
                                        name="benSharePercent"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].benSharePercent}
                                    />
                                </div>
                                <SwitchSlider
                                    name="signer"
                                    label="Подписант"
                                    style={{marginBottom: '20px'}}
                                    onChange={e => {
                                        addPersonTypes(e, index);
                                        handlePersonsInputChange(index, '', e)
                                    }}
                                    value={person.persons[index].signer}
                                />
                                <div style={{
                                    display: person.persons[index].signer ? 'flex' : 'none',
                                    marginBottom: '14px',
                                    flexWrap: "wrap"
                                }}>
                                    <Input
                                        className="info-inputs"
                                        id={`positionSigner${index}`}
                                        label="Должность"
                                        type="text"
                                        placeholder="—"
                                        name="position"
                                        style={{width: '333px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].position}
                                    />
                                    <SwitchSlider
                                        name="byProxy"
                                        label="Действует по доверенности"
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].byProxy}
                                    />
                                </div>
                                <div style={{
                                    display: person.persons[index].byProxy ? 'flex' : 'none',
                                    marginBottom: '40px'
                                }}>
                                    <Input
                                        className="info-inputs"
                                        id={`proxyName${index}`}
                                        label="Наименование доверенности"
                                        type="text"
                                        placeholder="—"
                                        name="proxyName"
                                        style={{width: '245px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].proxyName}
                                    />
                                    <Input
                                        className="info-inputs"
                                        id={`proxyNumber${index}`}
                                        label="Номер доверенности"
                                        type="text"
                                        placeholder="—"
                                        name="proxyNumber"
                                        style={{width: '245px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].proxyNumber}
                                    />
                                    <Input
                                        className="info-inputs"
                                        id={`proxyDateEnd${index}`}
                                        label="Срок действия доверенности"
                                        type="text"
                                        placeholder="—"
                                        name="proxyDateEnd"
                                        style={{width: '245px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].proxyDateEnd}
                                    />
                                    <Input
                                        className="info-inputs"
                                        id={`proxyDateStart${index}`}
                                        label="Дата доверенности"
                                        type="text"
                                        placeholder="—"
                                        name="proxyDateStart"
                                        style={{width: '245px'}}
                                        onChange={e => handlePersonsInputChange(index, '', e)}
                                        value={person.persons[index].proxyDateStart}
                                    />
                                </div>
                            </div>
                        </div>
                    })
                }
                <div className="flex-centered">
                    <AddButton onClick={handleAddPersonsForm}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: '40px', paddingRight: '20px'}}>
                    <Button
                        onClick={onContinue}
                        text="Продолжить"
                        style={{width: '100%'}}
                    />
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        person: store.person
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setPersonsInfo: Actions.setPersonsInfo,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PhisicalPerson)