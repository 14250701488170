import logo from '../images/logo.svg';
import logoSucces from '../images/logo-success.svg';
import plusIcon from '../images/plus-icon.svg';
import menuIcon1 from '../images/menu-icon1.svg';
import menuIcon2 from '../images/menu-icon2.svg';
import menuIcon3 from '../images/menu-icon3.svg';
import menuIconActive1 from '../images/menu-icon-active1.svg';
import menuIconActive2 from '../images/menu-icon-active2.svg';
import menuIconActive3 from '../images/menu-icon-active3.svg';

export const images = {
    logo,
    logoSucces,
    plusIcon,
    menuIcon1,
    menuIcon2,
    menuIcon3,
    menuIconActive1,
    menuIconActive2,
    menuIconActive3
};