import React from "react";

import "./styles.scss"

const SwitchSlider = ({value, label, name, onChange, style}) => (
    <div className="switch-slider" style={style}>
        <label className="label">
            <div className="toggle">
                <input className="toggle-state" type="checkbox" checked={value} name={name} value={value} onChange={onChange}/>
                <div className="toggle-inner">
                    <div className="indicator"/>
                </div>

            </div>
            <div className="label-text">{label}</div>
        </label>
    </div>
)

export default SwitchSlider