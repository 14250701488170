import React from "react";

import './styles.scss'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'


import Dropbox from "@uppy/dropbox";

const Uppy = require('@uppy/core');
const Tus = require('@uppy/tus');
const GoogleDrive = require('@uppy/google-drive');
const { Dashboard } = require('@uppy/react');
const Russian = require('@uppy/locales/lib/ru_RU')


class FileUpload extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showInlineDashboard: false,
            open: false
        };

        this.uppy = new Uppy({ locale: Russian, id: 'uppy1', autoProceed: true, debug: true, restrictions: {allowedFileTypes:[this.props.accept]}})
            .use(Tus, { endpoint: 'https://fplace-develop.moos.solutions/api/landing/upload'})
            .use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })
            .use(Dropbox, { companionUrl: 'https://companion.uppy.io' })
        this.handleModalClick = this.handleModalClick.bind(this)
    }

    componentWillUnmount () {
        this.uppy.close()
    }

    handleModalClick () {
        this.setState({
            open: !this.state.open
        })
    }

    render () {
        const { showInlineDashboard } = this.state;
        return (
            <div className={`document-upload ${this.props.className}`} style={this.props.style}>
                {!showInlineDashboard && (
                    <label>
                    {this.props.label}
                    <input style={{opacity: '0'}}
                    type="checkbox"
                    checked={showInlineDashboard}
                    onChange={(event) => {
                    this.setState({
                        showInlineDashboard: event.target.checked
                    })
                }}
                    />
                    </label>
                    )}
                {showInlineDashboard && (
                    <Dashboard
                        uppy={this.uppy}
                        plugins={['GoogleDrive', 'Dropbox']}
                        metaFields={[
                            { id: 'name', name: 'Name', placeholder: 'File name' }
                        ]}
                    />
                )}
            </div>
        )
    }
}



/*const FileUpload = ({id, label, className, accept, style}) => {
    return (
        <div className={`document-upload ${className}`} style={style}>
            <input type="file" id={id} accept={accept}/>
            <label htmlFor={id}>{label}</label>
        </div>
    )
};*/

export default FileUpload