// profile action types
export const SET_INN = "[PROFILE] SET_INN";
export const SET_OGRN = "[PROFILE] SET_OGRN";
export const SET_KPP = "[PROFILE] SET_KPP";
export const SET_FULL_NAME = "[PROFILE] SET_FULL_NAME";

export const SET_FIRSTNAME = "[PROFILE] SET_FIRSTNAME";
export const SET_LASTNAME = "[PROFILE] SET_LASTNAME";
export const SET_SECONDNAME = "[PROFILE] SET_SECONDNAME";

export const SET_PHONE = "[PROFILE] SET_PHONE";
export const SET_EMAIL = "[PROFILE] SET_EMAIL";

export const SET_ADDRESSES = "[PROFILE] SET_ADDRESSES";
export const SET_STAFF_INFO = "[PROFILE] SET_STAFF_INFO";

export const SET_AVERAGE_NUMBER = "[PROFILE] SET_AVERAGE_NUMBER";
export const SET_STAFF_DEBTS = "[PROFILE] SET_STAFF_DEBTS";

export const SET_BANK_ACCOUNTS = "[PROFILE] SET_BANK_ACCOUNTS";

//person action types
export const SET_PERSONS_INFO = "[PERSON] SET_PERSONS_INFO";

