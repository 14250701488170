import {combineReducers} from "redux";

import profile from "./profile";
import person from "./person";

const rootReducer = combineReducers({
    profile,
    person
});

export default rootReducer