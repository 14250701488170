import {SET_PERSONS_INFO} from '../constants/action-types';

const initialState = {
    persons: [{
        inn: "",
        lastName: "",
        firstName: "",
        secondName: "",
        type: [],
        sharePercent: "",
        benSharePercent: "",
        position: "",
        email: "",
        phone: "",
        byProxy: false,
        proxyName: "",
        proxyNumber: "",
        proxyDateEnd: "",
        proxyDateStart: "",
        positionHead: "",
        beneficary: false,
        CompanyHead: false,
        founder: false,
        signer: false,
    }]
};

function personReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PERSONS_INFO:
            return {...state, persons: action.payload};
        default:
            return state;
    }
}

export default personReducer